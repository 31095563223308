import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Logo from './Logo'

const NavStyles = styled.nav`
	margin-bottom: 3rem;
	.logo {
		transform: translateY(-25%);
	}
	ul {
		margin: 0;
		padding: 0;
		display: grid;
		grid-template-columns: 1fr 1fr auto 1fr 1fr;
		text-align: center;
		grid-gap: 2rem;
		list-style: none;
	}
	li {
		--rotate: -1.2deg;
		transform: rotate(var(--rotate));
		order: 1;
		&:nth-child(1) {
			--rotate: -1.2deg;
		}
		&:nth-child(2) {
			--rotate: 1.2deg;
		}
		&:nth-child(4) {
			--rotate: -2deg;
		}
		&:hover {
			--rotate: 3deg;
		}
	}
	a {
		font-size: 3rem;
		text-decoration: none;
		&:hover {
			color: var(--red);
		}
		/*&[aria-current='page'] {
			color: var(--red);
		}*/
	}
`

function Nav() {
	return (
		<NavStyles>
			<ul>
				<li>
					<Link to='/'>Home</Link>
				</li>
				<li>
					<Link to='/beers'>Beers</Link>
				</li>
				<li>
					<Link to='/'>
						<Logo />
					</Link>
				</li>
				<li>
					<Link to='/slicemasters'>SliceMasters</Link>
				</li>
				<li>
					<Link to='/order'>Order</Link>
				</li>
			</ul>
		</NavStyles>
	)
}

export default Nav
